<template>
  <page-content>
    <v-toolbar flat class="mx-2">

      <toolbar-btn icon="mdi-chevron-double-left" tooltip="-1 dzień" @click="prevDay()" />
      <toolbar-btn icon="mdi-chevron-left" tooltip="-1 godzina" @click="prevHour()" />
      <toolbar-btn icon="mdi-chevron-right" tooltip="+1 godzina" @click="nextHour()" />
      <toolbar-btn icon="mdi-chevron-double-right" tooltip="+1 dzień" @click="nextDay()" />
      <v-spacer />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="mx-1" color="info">
            <v-icon>mdi-plus</v-icon>
            Utwórz
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="addDispatcher()">
            <v-list-item-title>Zmiana dyżurnego</v-list-item-title>
          </v-list-item>
          <v-list-item @click="addPatrol()">
            <v-list-item-title>Zmiana patrolu</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <toolbar-btn icon="mdi-cloud-download-outline" tooltip="Zapis do pliku" @click="download()" />
    </v-toolbar>
    <v-row>
      <v-col>
        <VueCtkDateTimePicker class="mx-3" v-bind="$attrs" v-model="date" no-clear-button color="#f64e65"
          label="Wybierz datę i godzinę" format="YYYY-MM-DD HH:mm" formatted="dddd, DD MMMM YYYY HH:mm" />
      </v-col>
    </v-row>
    <patrol-working-time-card class="ma-4" flat v-for="group in this.items" :key="group.name"
      @deleteWorkingTime="deleteWorkingTime" :patrol="group.parent" :workingTimes="group.items">
    </patrol-working-time-card>
  </page-content>
</template>
<script>
import { addHours, addDays, parseJSON } from "date-fns";

// import { reactive, Vue } from "vue"
import UserService from "@/services/user.service";

export default {
  components: {},
  beforeMount() {
    this.date = new Date().toISOString();
  },
  data: () => ({
    date: null,
    items: [],
    edit: true,
  }),
  methods: {
    addDispatcher() {
      this.$router.push({
        name: "NewWorkingTime",
        params: {},
        hash: "#dispatcher",
      });
    },
    addPatrol() {
      this.$router.push({
        name: "NewWorkingTime",
        params: {},
        hash: "#patrol",
      });
    },
    prevHour() {
      this.date = addHours(parseJSON(this.date), -1).toISOString();
    },
    nextHour() {
      this.date = addHours(parseJSON(this.date), 1).toISOString();
    },
    prevDay() {
      this.date = addDays(parseJSON(this.date), -1).toISOString()
    },
    nextDay() {
      this.date = addDays(parseJSON(this.date), 1).toISOString();
    },
    download() {
      console.log("download :>> ");
      UserService.workingTimedownload(new Date(this.date)).then((response) => {
        console.log("ok", response.data);
        const blob = new Blob([response.data], {
          type: "application/pdf;charset=utf-8;",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${this.date}.pdf`;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    deleteWorkingTime(collection, index) {
      UserService.deleteWorkingTime(collection[index].id).then(() => {
        collection.splice(index, 1);
      });
      // this.$delete(this.workingTimes, id);
      // this.$delete(group.items, itemId);
      // console.log(this.items)
    },
  },

  watch: {
    date() {
      UserService.fetchWorkingTime(new Date(this.date)).then((res) => {
        const groups = new Map();
        for (const item of res.data.results) {
          let parent;
          if (item.parent) {
            parent = item.parent;
          } else if (item.user) {
            parent = item.user;
          }
          const key = parent.id;
          let group = groups.get(key);
          if (group == null) {
            group = { key: key, items: [], parent: parent };
            groups.set(key, group);
          }
          group.items.push(item);
        }
        this.items = [];
        for (const x of groups.values()) {
          this.items.push(x);
        }
      });
    },
  },
};
</script>
